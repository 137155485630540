import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstant } from '@constant/route.constant';
import { environment } from '@env';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent {
  protected readonly environment = environment;

  socialMediaList = [
    {
      imgPath: environment.imagePath + '/footer/youtube.svg',
      des: 'Youtube',
      url: 'https://www.youtube.com/channel/UCPEUGnTDRSISiZiSjUZOh7A?view_as=subscriber',
    },
    {
      imgPath: environment.imagePath + '/footer/facebook.svg',
      des: 'Facebook',
      url: 'https://www.facebook.com/iFASTHK/',
    },
    {
      imgPath: environment.imagePath + '/footer/instagram.svg',
      des: 'Instagram',
      url: 'https://www.instagram.com/ifastgm_hk/',
    },
    {
      imgPath: environment.imagePath + '/footer/linkedIn.svg',
      des: 'LinkedIn',
      url: 'https://www.linkedin.com/company/ifast-global-markets/mycompany/?viewAsMember=true',
    },
  ];

  downloadIconList = [
    {
      imageDes: 'App Store',
      type: 'app-store',
    },
    {
      imageDes: 'Google Play',
      type: 'google-play',
    },
    {
      imageDes: 'Android',
      type: 'android',
    },
  ];

  footerPage: any[] = [
    { name: 'footer.subpage.1', url: RouteConstant.DISCLAIMERS },
    { name: 'footer.subpage.2', url: RouteConstant.PRIVACY_STATEMENT },
    { name: 'footer.subpage.3', url: RouteConstant.SP_DISCLAIMERS },
    { name: 'footer.subpage.4', url: RouteConstant.CYBER_SECURITY },
    {
      name: 'footer.subpage.5',
      url: RouteConstant.PERSONAL_INFO_COLLECTION_STATEMENT,
    },
    { name: 'footer.subpage.6', url: RouteConstant.HKEX_DISCLAIMER },
    { name: 'footer.subpage.7', url: RouteConstant.MORNINGSTAR_DISCLAIMER },
    { name: 'footer.subpage.8', url: RouteConstant.TRADINGVIEW_DISCLAIMER },
  ];

  currentYear: number;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  openWindow(url: string): void {
    window.open(url);
  }

  getIcon(type: string): string {
    return environment.imagePath + '/footer/' + type + '.svg';
  }

  routeToPage(url: string): void {
    const lang = localStorage.getItem('locale') || 'en';

    this.router.navigate([
      '/' + lang + '/' + RouteConstant.DISCLAIMERS_AND_STATEMENT + '/' + url,
    ]);
  }

  clickedDownloadBtn(type: string): void {
    if (type === 'app-store') {
      window.open('https://apps.apple.com/sg/app/igm/id1577869712');
    } else if (type === 'google-play') {
      window.open(
        'https://play.google.com/store/apps/details?id=com.igmnew&hl=en_US&pli=1'
      );
    } else if (type === 'android') {
      window.open(
        'https://secure.ifastgp.com.hk/hk/igp/acl/attachment/iGM_Mobile_New.apk'
      );
    }
  }
}
